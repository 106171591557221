<template>
  <Content
    :current-id="currentId" 
    :init='{ dispatchAction: "products_categories", currentId: currentId }'
    :submenu='{ state: true, name: "webshop/categories", currentData: "currentWebshop"}'
    :toolbar='{ init: false, name: "management/connections", dispatchAction: "CONNECTION", currentData: "currentConnection", currentId: currentId}' 
  />
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { useContentStore } from "@/stores/content";
import { useRoute } from "vue-router";
import Content from '@/components/Content.vue';
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Content,
  },
  setup(props, {emit}) {
    const route = useRoute();
    const { t } = useI18n();
    const contentStore = useContentStore();

    const currentId = computed(() => {
      return route.params.uid
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      contentStore.setBreadcrumbTitle(t('titles.Category') + ' - ' + currentId.value);
    })

    return {
      currentId,
    }
  }
})
</script>
